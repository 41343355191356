import { axios } from "@/utils/request";

// 2030101 获取报告分页列表
export function getCasePage(data) {
  return axios({
    url: '/tongueCase/getCasePage',
    method: 'post',
    data
  })
}

// 2030102 获取报告详情
export function getTongueCaseVo(data) {
  return axios({
    url: '/tongueCase/getTongueCaseVo',
    method: 'post',
    data
  })
}

// 1030103 获取报告商品详情
export function getCaseGoodsVo(data) {
  return axios({
    url: '/tongueCase/getCaseGoodsVo',
    method: 'post',
    data
  })
}
