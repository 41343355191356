<template>
  <div class="report_list_wrap" @scroll="handleScroll">
    <div class="report_item" v-for="(item, index) in list" :key="index" @click="toDetail(item.tongueCaseId)"  >
      <div class="report_left" >
        <div>{{ item.shopName }}</div>
        <div>{{ item.createTime }}</div>
      </div>
      <div class="report_right" >
        <div class="line" ></div>
        查看健康报告
      </div>
    </div>

    <van-empty
      v-if="list.length <= 0 && !loading"
      :image="empty"
      description="暂无报告"
    />

  </div>
</template>
<style scoped lang="less" >
/deep/ .van-empty__description {
  color: #03CFAF;
}
@import "index";
</style>
<script>
import {
  getCasePage
} from '@/api/report'
import empty from './img/6.png'
export default {
  name: 'report',
  data() {
    return {
      page: 1,
      pageSize: 20,
      list: [],
      empty,
      loading: true,
    }
  },
  methods: {
    handleScroll(event) {
      const {scrollTop, clientHeight, scrollHeight} = event.target
      if (scrollTop + clientHeight >= scrollHeight - 50){
        this.page ++
        this.getCasePage()
      }
    },
    getCasePage() {
      const { page, pageSize } = this
      this.loading = true
      getCasePage({ page, pageSize }).then(res => {
        this.loading = false
        if(res.success) {
          this.list = this.list.concat(res.data)
        }
      })
    },
    toDetail(tongueCaseId) {
      this.$router.push({
        name: 'report',
        params: { tongueCaseId }
      })
    }
  },
  mounted() {
    this.getCasePage()
  }
}
</script>
